import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Text, Image } from "../components/Core"
import { Section, Columns } from "../components/Containers"
import { Button } from "../components/Button"

const SinglePodcast = ({ data, pageContext, location }) => {
  const post = data.allPodcastsJson.nodes[0]
  const { language } = pageContext
  const queryUrl = `/${data.query.nodes[0].title}`
  const related = data.relatedPodcasts.edges.filter(edge => edge.next !== null)

  return (
    <Layout className="podcast" language={language}>
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        pathname={location.pathname}
        lang={language}
      />

      <Section
        zeroBottom
        colorBack
        className="podcast__clip single-podcast-hero relative">
        <Columns sideColumnsSize={2} className="pq__hero">
          <div className="column ps__heading mb-3--mobile">
            <Button goBack href={queryUrl} />
            <Text
              as="p"
              className="ps__episode"
              text={`EPISODE ${post.episode}`}
            />
            <Text
              className="ps__h1 px-40--mobile"
              as="h1"
              text={post.heading}
            />
            <a
              href={post.url}
              target="_blank"
              aria-label="open link in new tab"
              title="Listen on Youtube"
              className="ps__btn mx-40--mobile">
              WATCH VIDEO
            </a>
          </div>
          <div className="column is-2"></div>
          <div className="column">
            <Image publicId={post.heroImage} />
          </div>
        </Columns>
      </Section>

      <Section>
        <Columns sideColumnsSize={2}>
          <div className="column">
            <Text className="ps__body" text={post.body} />
          </div>
        </Columns>
      </Section>

      {related && related.length > 0 && (
        <Section>
          <Columns sideColumnsSize={2}>
            <div className="column">
              <Text
                as="h3"
                className="color-white"
                text="Listen to other episodes"
              />
              <div className="pq__list">
                {related.map((edge, index) => (
                  <div key={edge.next.title} className="pq__card">
                    <Text
                      className="pq__card-ep px-0"
                      as="p"
                      text={`Episode ${edge.next.episode}`}
                    />
                    <Text
                      className="pq__card-heading px-0"
                      as="h3"
                      text={edge.next.heading}
                    />
                    <Text
                      className="pq__card-description px-0"
                      as="p"
                      text={edge.next.description}
                    />
                    <Link
                      className="pq__card-btn"
                      to={"/" + edge.next.title}
                      title="Listen to the full episode">
                      Listen to Episode
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </Columns>
        </Section>
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  query singlePodcastPage($title: String!, $episode: Int!) {
    allPodcastsJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        episode
        heading
        heroImage
        url
        body
      }
    }
    query: allUniquePagesJson(filter: { title: { eq: "podcast/" } }) {
      nodes {
        title
      }
    }
    relatedPodcasts: allPodcastsJson(
      filter: { episode: { ne: $episode } }
      limit: 4
      sort: { fields: episode }
    ) {
      edges {
        next {
          episode
          heading
          title
          description
        }
      }
    }
  }
`

export default SinglePodcast
